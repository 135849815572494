import React, { useRef } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
// import Banner from '../../Components/Banner/Banner';
// import Pricing from '../../Components/Pricing/Pricing';
import MediaBanner from '../../Components/MediaTraing/MediaBanner/MediaBanner';
import MediaPackages from '../../Components/MediaTraing/MediaPackages';

const MediaTraning = () => {
    const targetRef = useRef(null);

    const scrollToComponent = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
            <Navbar />
            <MediaBanner scrollToComponent={scrollToComponent} />
            <MediaPackages targetRef={targetRef} />
            <Footer />
        </div>
    );
};

export default MediaTraning;
import React from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Banner from './Components/Banner/Banner'
// import Banner2 from './Components/Banner2/Banner2';
import WhyTookimedia from './Components/whytooki/whytooki';
import BoostSales from './Components/services/services';
// import ClientSay from './Components/ClientSay/ClientSay';
import HowItWorks from './Components/worksteps/worksteps';
// import Pricing from './Components/Pricing/Pricing';
// import MoneyBack from './Components/MoneyBack/MoneyBack';
// import Stripe from './Components/Stripe/Stripe';
// import Faq from './Components/Faq/Faq';
import Testimonial from './Pages/testimonial';
// import Newsletter from './Components/NewsLetter/Newsletter';
import OurClientsPage from './Components/ourclients/ourclients';
import Calendly from './Components/calendly';




function App() {
  return(
    <div id='/'>
      <Navbar />
      <Banner />
      <OurClientsPage/>
      {/* <Banner2 /> */}
      <WhyTookimedia />
      <BoostSales />
      {/* <ClientSay /> */}
      <HowItWorks />
      <Calendly/>
      {/* <Pricing /> */}
      {/* <MoneyBack /> */}
      {/* <Stripe /> */}
      {/* <Faq /> */}
      {/* <Newsletter /> */}
      <Testimonial/>
      <Footer />
    </div>
    
  )
}

export default App;

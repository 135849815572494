import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';




import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const TextCarousel = ({ slides }) => {

  return (
    <Swiper
    modules={[Navigation, Pagination, Scrollbar, A11y,Autoplay]} 
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      effect="fade"

      className="relative"
      
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index} className="relative flex items-center justify-center  text-white mt-12">
          <div
            className="bg-image-card p-6 rounded-lg shadow-md text-center"
            style={{ backgroundImage: `url(${slide.backgroundImage})` }}
          >
            <h2 className="text-xl text-customColor font-semibold mb-2 mx-12">{slide.title}</h2>
            <p className="text-white-600 mb-12 mx-20 text-left">{slide.description}</p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TextCarousel;

import React, { useState, useRef, } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './servicedropdown.css'
// import MediaTraning from '../MediaTraning/MediaTraning';

function ServiceDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownTimeout = useRef(null);

  // const customStylesDesktop = {
  //   backgroundColor: 'white',
  //   color: 'black',
  //   border: 'none',
  //   boxShadow: 'none',
  //   padding: 0,
  //   marginRight: '2rem',
  //   cursor: 'pointer',
  // };

  // const customStylesMobile = {
  //   // backgroundColor: 'black',
  //   // color: 'white',
  //   border: 'none',
  //   boxShadow: 'none',
  //   padding: 0,
  //   // marginRight: '2rem',
  //   cursor: 'pointer',
  //   fontSize: '25px'
  // };

  const handleMouseEnter = () => {
    // Open the dropdown on hover only if the screen width is greater than 768 pixels
    if (window.innerWidth > 768) {
      setIsOpen(true);
    }
  };

  const handleMouseLeave = () => {
    // Close the dropdown after a short delay
    dropdownTimeout.current = setTimeout(() => {
      setIsOpen(false);
    }, 300); // Adjust the delay as needed
  };

  const handleClick = () => {
    // Toggle the dropdown on click
    setIsOpen(!isOpen);
  };

  const handleDropdownMouseEnter = () => {
    // Prevent the dropdown from closing when the mouse enters it
    clearTimeout(dropdownTimeout.current);
  };

  const handleDropdownMouseLeave = () => {
    // Close the dropdown when the mouse leaves it
    setIsOpen(false);
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     // Close the dropdown when the screen width changes to mobile size
  //     if (window.innerWidth <= 768) {
  //       setIsOpen(false);
  //     }
  //   };

  //   // Add event listener for window resize
  //   window.addEventListener('resize', handleResize);

  //   // Remove event listener on component unmount
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  // const currentStyles = window.innerWidth > 768 ? customStylesDesktop : customStylesMobile;

  return (
    <Dropdown
      show={isOpen}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <Dropdown.Toggle id="dropdown-basic">
        Services
      </Dropdown.Toggle>

      <Dropdown.Menu
        onMouseEnter={handleDropdownMouseEnter}
        onMouseLeave={handleDropdownMouseLeave}
        ref={dropdownRef}

      >

        <Dropdown.Item href='/media-relations'>Media relations</Dropdown.Item>

        <Dropdown.Item href='/MediaRelation'>Media relations</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Digital marketing</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Brand promotions</Dropdown.Item>
        <Dropdown.Item href="/media-management">Reputation management</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Wikipedia biography publishing</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Special events</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ServiceDropdown;

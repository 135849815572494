import React from "react";
import Banner from "../../../Assets/austin-distel-wD1LRb9OeEo-unsplash.jpg";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const MediaBanner = ({ scrollToComponent }) => {
  return (
    <div className="relative bg-gray-200  overflow-hidden">
      {/* Image */}
      <img
        className="object-cover object-center h-screen w-screen "
        src={Banner} // Replace this with your image URL
        alt="Banner info"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-100"></div>

      {/* Text on top of the image */}
      <div className="absolute inset-0 flex items-center justify-center text-center text-white">
        <div>
          <h1 className="text-4xl lg:text-6xl font-bold mb-4">
            Media Relation
          </h1>
          <p className="text-lg lg:w-[600px] leading-2 lg:leading-20">
            Tooki Media has cultivated strong partnerships with top media
            outlets, publications, and podcasts. We work with our extensive
            network of journalists and reporters to secure strategic placements
            in high-traffic media for your brand. Our commitment is securing
            media coverage that truly matters, translating into tangible
            benefits such as increased organic traffic and heightened brand
            awareness. Book a free consultation today to discover how our media
            expertise can amplify your brand's presence. .
          </p>

          <div className="mt-5">
            <button
              onClick={scrollToComponent}
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-customColor_red px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-customColor focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-customColor"
            >
              Choose a Package
              <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaBanner;

import React from 'react';

const BlogList = ({ blogs, handleBlogClick }) => {
  return (
    <div className='blogs'>
      {blogs.map((blog, index) => (
        <div className='blog' key={index} onClick={() => handleBlogClick(blog)}>
          <h2 className='text-xl font-bold'>{blog.title}</h2>
          <p className='text-gray-500'>{blog.description}</p>
        </div>
      ))}
    </div>
  );
};

export default BlogList;

import React from "react";
import { GiSwordBrandish, GiAwareness,GiRelationshipBounds } from 'react-icons/gi'
import { TbSeo, TbTrafficLights } from 'react-icons/tb'
import { SiTrustpilot,SiWikipedia } from 'react-icons/si'

const BoostSales = () => {
  const cards = [
    {
      icon: GiSwordBrandish,
      title: "Brand Promotions",
      description:
        "Let's make your brand the talk of the town, from local markets to the online sphere.        ",
    },
    {
      icon: TbSeo,
      title: "Digital Marketing",
      description:
        "Navigate the digital jungle with precision. We blend creativity with analytics to ensure your brand stands out.",
    },
    {
      icon: TbTrafficLights,
      title: "Special Events",
      description:
        "Turn moments into memories. Our events are not just gatherings; they're experiences that leave an indelible mark.",
    },
    {
      icon: GiAwareness,
      title: "Brand Communications",
      description:
        "Your voice matters. We amplify it across channels, ensuring your message resonates with your audience.",
    },
    {
      icon: SiTrustpilot,
      title: "Crisis Management",
      description:
        "When the storms hit, we're your anchor. Navigate challenges with resilience and grace. ",
    },
    {
        icon: GiRelationshipBounds,
        title: "Media Relations",
        description:
          "Building bridges between your brand and the media landscape, we ensure your story is told authentically",
      },
      {
        icon: SiWikipedia,
        title: "Wikipedia Biography Publishing",
        description:
          "Your brand legacy deserves a place in the digital archives. Let us etch your story on the pages of Wikipedia.",
      },
  ];

  return (
    <div className="text-center mx-12">
      <div className="p-4">
        <h3 className="text-3xl font-bold mb-4">Explore Our Services</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {cards.map((card, index) => (
            <div
              key={index}
              className={`bg-white p-4 rounded-md shadow-md transform transition-transform duration-200 hover:-translate-y-1`}
              style={{
                transform: `translateZ(${(index - 1) * 10}px)`, // Adjusted index to start from -10px
                boxShadow: `0px 0px ${(index - 1) * 5}px rgba(0, 0, 0, 0.2)`, // Adjusted index to start from -5px
                background: `linear-gradient(145deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.25) 100%)`,
              }}
              
              
            >
              <card.icon size={32} className="text-customColor_red mb-2 mx-auto" />
              <h2 className="text-xl text-customColor font-semibold">{card.title}</h2>
              <p className="text-gray-600">{card.description}</p>
            </div>
          ))}
        </div>
        <button className="bg-customColor_red text-white py-2 px-4 mt-4 rounded-full hover:bg-customColor">
          See All Services
        </button>
      </div>
    </div>
  );
};

export default BoostSales;

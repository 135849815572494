import React from 'react';
import Banner from '../../../Assets/austin-distel-wD1LRb9OeEo-unsplash.jpg';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

const MediaBanner = ({ scrollToComponent }) => {
    return (
        <div className="relative bg-gray-200 h-500px overflow-hidden">
            {/* Image */}
            <img
                className="object-cover object-center h-80 w-full mt-9"
                src={Banner} // Replace this with your image URL
                alt="Banner info"
            />

            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-100"></div>

            {/* Text on top of the image */}
            <div className="absolute inset-0 flex items-center justify-center text-center text-white">
                <div className="flex flex-col items-center justify-center text-center">
                    <h1 className="text-4xl mt-3 lg:text-6xl font-bold mb-4">
                        Online Reputation Management
                    </h1>
                    <p className="text-lg lg:w-[600px] leading-2 lg:leading-20">
                        Thousands of established businesses and brands have seen their online reputation tarnished and are paying the price. Our team of reputation management professionals is here to help get you back on track with online reviews, removing negative articles, creating positive backlinks, and more.
                    </p>
                    <div className="mt-2">
                        <button
                            onClick={scrollToComponent}
                            type="button"
                            className="inline-flex items-center gap-x-2 rounded-md bg-customColor_red px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-customColor focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-customColor"
                        >
                            Book now
                            <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default MediaBanner;

import React from "react";
// import svg from "../../Assets/undraw_articles_wbpb.svg";
import MyCarousel from "../whycarousel";
// import { VscTasklist } from "react-icons/vsc";

const WhyTookimedia = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto flex flex-col md:flex-row items-center md:items-start justify-between">
        <div className="md:w-1/2 mx-6 mb-12">
          {/* <img src={svg} alt="" height={315} width={560} /> */}
          <h2 className="font-bold text-center md:text-left text-3xl mb-4 ">
            Our work
          </h2>
          < MyCarousel/>
        </div>
        <div className="md:w-1/2 lg:mt-10">
          <h2 className="font-bold text-center md:text-left text-3xl mb-4 ">
            Why Choose Tooki Media
          </h2>
          <div className="mb-4">
            <h4 className="font-bold text-center text-customColor_red text-base ">
              Expertise Rooted in Global Marketing Communications
            </h4>
            <p>
              We offer a unique combination of global marketing communications
              expertise and local authenticity that sets us apart from the
              competition.
            </p>
          </div>
          <div className="mb-4">
            <h4 className="font-bold text-center text-customColor_red text-base">Conversion Magic</h4>
            <p>
              Our strategies are not just about visibility; they're about
              turning curious clicks into devoted clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyTookimedia;

import React, { useState } from 'react';
import Axios from 'axios';
import BlogList from './BlogList';
import ShowBlog from './ShowBlog';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [showBlog, setShowBlog] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);

  const fetchBlogs = async () => {
    try {
      const response = await Axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/@TookiMediaPR');
      const data = response.data.feed.items;
      setBlogs(data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchBlogs();
  }, []);

  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);
    setShowBlog(true);
  };

  const handleCloseShowBlog = () => {
    setSelectedBlog(null);
    setShowBlog(false);
  };

  return (
    <div className='container mx-auto px-4 py-6'>
      {showBlog && selectedBlog && <ShowBlog blog={selectedBlog} handleCloseShowBlog={handleCloseShowBlog} />}
      {!showBlog && <BlogList blogs={blogs} handleBlogClick={handleBlogClick} />}
    </div>
  );
};

export default Blog;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AboutUs from './Pages/AboutUs/AboutUs';

import Faq from './Components/Faq/Faq';
import Blog from './Components/blog/Blog';

import ContactUs from './Pages/ContactUs/ContactUs';
import MediaRelations from './Pages/MediaRelations/MediaRelation';
import MediaManagement from './Pages/MediaManagement/MediaManagement';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />
  },
  {
    path: '/aboutus',
    element: <AboutUs />
  },
  {
    path: '/contactus',
    element: <ContactUs />
  },
  {
    path: '/MediaRelation',
    element: <MediaRelations />
  },
  {
    path: '/media-management',
    element: <MediaManagement />
  },
  {
    path: '/Faq',
    element: <Faq />
  },
  {
    path: '/Blog',
    element: <Blog />
  }
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

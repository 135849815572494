import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Logo from "../../Assets/logos/Passport-Legacy-350x100-WP-05-06.png.webp";
import Logo2 from "../../Assets/logos/Logo_1-1131x800.png";
import Logo3 from "../../Assets/logos/PinkClearessence-Logo-Black.png";
import Logo4 from "../../Assets/logos/Sims-logo-Black2-1024x292.png";
import Logo5 from "../../Assets/logos/WhatsApp_Image_2023-09-07_at_10.32.38_AM__1_-removebg-preview.png";
import Logo6 from "../../Assets/logos/cropped-20200409_114524_0000.png";
import Logo7 from "../../Assets/logos/cropped-cropped-logo-jpeg.jpg";
import Logo8 from "../../Assets/logos/samsung-logo-png-1304.png";
import Logo9 from "../../Assets/logos/logom1.png";
import Logo10 from "../../Assets/logos/logo (1).png";

import "bootstrap/dist/css/bootstrap.min.css";

function CarouselFadeExample() {
  const images = [
    {
      src: Logo,
      alt: "First slide",
      caption: "First slide label",
      description: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
    },
    {
      src: Logo2,
      alt: "Second slide",
      caption: "Second slide label",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      src: Logo3,
      alt: "Third slide",
      caption: "Third slide label",
      description: "Praesent commodo cursus magna, vel scelerisque nisl consectetur.",
    },
    {
        src: Logo4
    },
    {
       src: Logo5
    },
    {
        src: Logo6
    },
    {
        src: Logo7
    },
    {
        src: Logo8
    },
    {
        src: Logo9
    },
    {
        src: Logo10
    }
  ];

  // const imageStyles = {
  //   display: "block",
  //   margin: "0 auto", // Center the image
  //   maxWidth: "300px", // Reduce the size of the image
  //   maxHeight: "50vh",
  //   objectFit: "cover", 
  //   overflow: 'hidden'
  // };

 

  return (
    <Carousel fade className="my-5 fixed-height-carousel" indicators={false} interval={3000} style={{
        maxWidth: "100vw",
        maxHeight: "400px",
        objectFit: "contain",
        display: "block",
    margin: "0 auto", 
    overflow: "hidden"
      }}>
         <h2 className="font-bold text-center text-2xl my-7">
       Our Clients
          </h2>
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          {/* <Carousel.Caption>
            <h3 className="text-dark">{image.caption}</h3>
            <p>{image.description}</p>
          </Carousel.Caption> */}
          <img src={image.src} alt={image.alt} className="img-fluid fixed-size-image" style={{
        maxWidth: "300px",
        maxHeight: "200px",
        objectFit: "cover",
        display: "block",
    margin: "0 auto", 
    overflow: "hidden"
      }}/>
        </Carousel.Item>
      ))}
      {/* <Carousel.Indicators style={carouselIndicators} /> */}
    </Carousel>
  );
}

export default CarouselFadeExample;

import React from 'react';
import moment from 'moment';

const ShowBlog = ({ blog }) => {
  return (
    <div className='show-blog'>
      <h2 className='text-2xl font-bold'>{blog.title}</h2>
      <div className='mt-4 flex items-center'>
        <img
          src={blog.author.image}
          alt={blog.author.name}
          className='w-10 h-10 rounded-full'
        />
        <p className='ml-4 text-gray-500'>{blog.author.name}</p>
        <p className='ml-2 text-gray-500'>{moment(blog.pubDate).format('MMM DD, YYYY hh:mm')}</p>
      </div>
      <div className='content mt-4'>
        <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>
      </div>
      <button className='mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600' onClick={() => ShowBlog(false)}>
        Close
      </button>
    </div>
  );
};

export default ShowBlog;

import React from "react";

const Card = ({ title, description, icon }) => {
  return (
    <div className="flex flex-col items-center justify-center rounded-2xl shadow-md bg-white h-full">
      <div className="rounded-t-2xl w-full p-6 flex items-center justify-center bg-customColor text-customColor_red relative">
        <div
          className="rounded-full bg-customColor p-3 absolute top-1/4 transform -translate-y-1/2"
        >
          {icon}
        </div>
      </div>
      <div className="card__content flex flex-col items-center justify-center pt-6">
        <h2>{title}</h2>
        <p className="my-6 mx-6">{description}</p>
        <button className="bg-customColor_red text-white px-4 py-2 mb-4 rounded-md hover:bg-customColor">
          Book a call
        </button>
      </div>
    </div>
  );
};

export default Card;

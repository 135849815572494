import React, { useState } from "react";
import "./Navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import Logo from "../../Assets/tinywow_Tooki media logo_39820758.svg";
import { Link } from "react-router-dom";
// import ServicesDropdown from "./ServicesDropdown";
import ServiceDropdown from "../../Pages/Services/servicesDropdown";
// import Faq from "../Faq/Faq";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="tooki__navbar">
      <div className="tooki__navbar-container">
        <div className="tooki__navbar-container_logo">
          <Link to="/">
            <img src={Logo} alt="LOGO" />
          </Link>
        </div>

        <div className="tooki__navbar-container_header">
          <ul>
            <li >
              <Link to="/aboutus">About us</Link>
            </li>
            <li>
              {/* <Link to="/">Services</Link> */}
              < ServiceDropdown />
            </li>
            <li>
              <Link to="/Blog">Blog</Link>
            </li>
            <li>
              <Link to="/aboutus">Buy Backlinks</Link>
            </li>
            {/* <li>
              <Link to="/aboutus">How it Works</Link>
            </li> */}
            <li>
              <Link to="/contactus">Contact Us</Link>
            </li>
            <li>
              <Link to="/Faq">FAQ</Link>
            </li>
            {/* <li>
              <Link to="/aboutus">Projects </Link>
            </li> */}
          </ul>
        </div>
        <div className="tooki__navbar-container_menu">
          {isOpen ? (
            <AiOutlineClose
              color="000"
              size={27}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
          ) : (
            <GiHamburgerMenu
              color="000"
              size={27}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
          )}
          {isOpen && (
            <div className="tooki__navbar-container_menu-items slide-in-top">
              <ul>
                <li>
                  <Link to="/aboutus">About us</Link>
                </li>
                <li>
                  {/* <Link to="/">Services</Link> */}
                  < ServiceDropdown />
                </li>
                <li>
                  <Link to="/Blog">Blog</Link>
                </li>
                <li>
                  <Link to="/aboutus">Buy Backlinks</Link>
                </li>
                <li>
                  <Link to="/aboutus">How it Works</Link>
                </li>
                <li>
                  <Link to="/contactus">Contact Us</Link>
                </li>
                <li>
                  <Link to="/Faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/aboutus">Projects </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
